<!--
描述: 
    主板型
-->
<template>
	<main class="dark:bg-18181F">
		<div class="flex min-h-screen flex-col">
			<!--
			.##.....##.########....###....########..########.########.
			.##.....##.##.........##.##...##.....##.##.......##.....##
			.##.....##.##........##...##..##.....##.##.......##.....##
			.#########.######...##.....##.##.....##.######...########.
			.##.....##.##.......#########.##.....##.##.......##...##..
			.##.....##.##.......##.....##.##.....##.##.......##....##.
			.##.....##.########.##.....##.########..########.##.....##
			-->

			<header class="fixed left-0 top-0 w-screen bg-gray-100 z-[1000] shadow">
				<div class="container flex items-center py-2 bg-gray-100">
					<div class="flex items-center mr-3">
						<router-link to="/" @click="resetSearchSuggestion()">
							<img src="/img/logo.png" alt="Logo" class="h-8 hidden" />
							<picture>
								<source srcset="/img/wlog.png 1x, /img/wlog@2x.png 2x, /img/wlog@3x.png 3x" />
								<img src="/img/wlog.png" alt="Logo" class="h-8" />
							</picture>
						</router-link>
					</div>

					<div class="hidden lg:block">
						<router-link
							to="/"
							class="inline-block text-center py-1 text-15 font-500 border-b-2 ml-3 mr-5"
							:class="{
								'border-main-500 font-500  text-[#222]': $route.meta.indexTab === 1,
								'border-transparent text-[#8A8A8A]': $route.meta.indexTab !== 1,
							}"
							>首頁</router-link
						>
						<router-link
							to="/explore"
							class="inline-block text-center py-1 text-15 font-500 border-b-2"
							:class="{
								'border-main-500 font-500 text-[#222]': $route.meta.indexTab === 2,
								'border-transparent text-[#8A8A8A]': $route.meta.indexTab !== 2,
							}"
							>探索</router-link
						>
					</div>

					<!-- Navigation and Search -->
					<div class="flex-1 hidden lg:block">
						<div class="relative">
							<div class="flex items-center flex-1 mx-5 w-full max-w-[400px]">
								<div class="relative border w-full">
									<!-- @keyup="getSearchSuggestion()" -->

									<input
										type="text"
										placeholder="搜尋關鍵字"
										class="pl-10 pr-5 py-2 w-full rounded-8 bg-white"
										@keyup.enter="searchDo()"
										v-model="search"
										@click="onClickSearch()"
									/>
									<img
										src="/img/02.png"
										alt="Search"
										class="absolute left-2 top-1/2 transform -translate-y-1/2 w-5 h-5 text-gray-400"
									/>
									<img
										src="/img/88.png"
										alt="clear"
										class="absolute right-2 top-1/2 transform -translate-y-1/2 w-5 h-5 text-gray-400 pointer"
										v-if="search.length > 0"
										@click="search = ''"
									/>
								</div>
							</div>

							<!-- Popup Container -->
							<div
								class="bg-white p-4 rounded-16 max-w-md w-full absolute top-[50px] left-[20px] shadow"
								v-if="isShowSearchSuggestion"
								v-on-click-outside="closeSearchSuggestion"
							>
								<div v-if="searchSuggestion.articles.length > 0">
									<h2 class="text-14 text-[#8A8A8A] mb-4">推薦文章</h2>

									<div>
										<router-link
											:to="`/article/${x.id}`"
											class="text-[#222] block mb-2 truncate"
											v-for="(x, i) of searchSuggestion.articles"
											:key="i"
											@click="
												isShowSearchSuggestion = false;
												hideSearch();
											"
											>{{ x.name }}</router-link
										>
									</div>
									<div class="h-1 border-b border-[#F1F1F1] mb-3"></div>
								</div>

								<div v-if="searchSuggestion.users.length > 0">
									<h2 class="text-14 text-[#8A8A8A] mb-4">創作者</h2>

									<div class="grid grid-cols-2 gap-3">
										<div class="w-full">
											<router-link
												:to="`/user/${x.id}`"
												class="block w-full mb-2"
												v-for="(x, i) of searchSuggestion.users"
												@click="
													isShowSearchSuggestion = false;
													hideSearch();
												"
												:key="i"
											>
												<div class="flex items-center">
													<img
														class="w-10 h-10 rounded-full mr-2"
														:src="asset(x.photo, '_user.png')"
													/>
													<div class="flex-1">
														<div class="text-14 font-500 max-w-[120px] truncate block">
															{{ x.name }}
														</div>
														<p
															class="text-[#8A8A8A] text-12 w-full truncate"
															v-if="x.username"
														>
															@{{ x.username }}
														</p>
													</div>
												</div>
											</router-link>
										</div>
									</div>
									<div class="h-1 border-b border-[#F1F1F1] mt-5 mb-3"></div>
								</div>

								<div v-if="searchSuggestion.tags.length > 0">
									<h2 class="text-14 text-[#8A8A8A] mb-4">標籤</h2>
									<div class=" ">
										<router-link
											:to="`/tag/${x.id}`"
											v-for="(x, i) of searchSuggestion.tags"
											:key="i"
											class="bg-[#F1F1F1] py-1 px-4 rounded-full inline-block mr-2 mb-2 pointer"
											@click="
												isShowSearchSuggestion = false;
												search = '';
											"
										>
											#{{ x.name }}</router-link
										>
									</div>
								</div>
							</div>
						</div>
					</div>

					<!-- Buttons and Profile -->
					<div class="ml-auto lg:block">
						<div class="flex items-center">
							<router-link
								v-if="store.isLogin"
								to="/article/new"
								@click="resetSearchSuggestion()"
								class="border-[#8A8A8A] border text-[#8A8A8A] text-14 px-4 py-1 rounded-8"
							>
								<div class="flex items-center">
									<img src="/img/50.png" class="h-4 w-4 mr-2" />
									創作
								</div>
							</router-link>

							<!-- Notification Icon and Popup -->
							<div class="hidden lg:block relative ml-4" v-if="store.isLogin">
								<button class="relative text-gray-600" @click.stop="toggleNotification">
									<img src="/img/51.png" alt="Notifications" class="h-8 w-8 rounded-full" />
									<span
										v-if="useNotification.unreadCount > 0"
										class="absolute top-0 right-[-5px] inline-block w-5 h-5 text-center text-12 text-white bg-rise-500 rounded-full"
									>
										{{ useNotification.unreadCount }}
									</span>
								</button>
								<div
									v-if="isShowNotification"
									class="absolute top-12 right-0 bg-white shadow-lg rounded p-4 w-[400px] z-[1000] overflow-auto max-h-[400px]"
									ref="notificationPopup"
									asdsdsadsadsa
									v-on-click-outside="closeNotification"
								>
									<div class="flex items-center justify-between border-b pb-2 mb-2">
										<div class="flex">
											<div class="relative">
												<div
													class="flex items-center space-x-2 border-b-2 pb-2 px-2 pointer"
													@click="changeNotificationType(1)"
													:class="{
														'border-main-400': notificationTypeID == 1,
														'border-transparent': notificationTypeID != 1,
													}"
												>
													<img src="/img/18.png" alt="All Notifications" class="h-5 w-5" />
													<span>通知</span>
												</div>
												<!-- red dot -->
												<div
													class="w-2 h-2 rounded-full bg-[#F73030] absolute right-0 top-0"
													v-if="
														useNotification.notifications.filter((z) => z.is_read == false)
															.length > 0
													"
												></div>
											</div>
										</div>
										<button
											class="text-sm"
											@click="setAllRead()"
											:disabled="useNotification.unreadCount <= 0"
											:class="{
												'opacity-30': useNotification.unreadCount <= 0,
												'text-main-400': useNotification.unreadCount > 0,
											}"
										>
											全部標示已讀
										</button>
									</div>

									<!-- normal notification -->
									<div v-if="notificationTypeID == 1">
										<div v-if="useNotification.notifications.length > 0">
											<router-link
												:to="
													x.type_id == 3 || x.type_id == 4 || x.type_id == 6
														? x.url + '#comment'
														: x.url
												"
												class="flex items-center space-x-2 px-2 py-3"
												:class="{ 'bg-[#F7F7F7]': x.is_read == false }"
												v-for="(x, i) of useNotification.notifications"
												:key="i"
												@click="clickNotification(x)"
											>
												<div class="w-10">
													<img
														v-if="x.user"
														:src="asset(x.user.photo, '_user.png')"
														:alt="x.user.name"
														class="h-10 w-10 rounded-full"
													/>
												</div>
												<div class="flex-1">
													<div class="flex items-center">
														<div class="mr-2 font-500 text-[#8A8A8A] text-16" v-if="x.user">
															{{ x.user.name }}
														</div>
														<div class="text-[#C5C5C5] text-14 font-400">
															<div v-if="x.type_id == 1">發佈了文章</div>
															<div v-if="x.type_id == 2">開始追蹤你</div>
															<div v-if="x.type_id == 3">回覆你的文章</div>
															<div v-if="x.type_id == 4">回覆你的留言</div>
															<div v-if="x.type_id == 5">喜歡你的文章</div>
															<div v-if="x.type_id == 6">喜歡你的留言</div>
														</div>
													</div>
													<div
														class="break-all line-clamp-2 h-[50px] w-full text-[#8A8A8A]"
														v-if="x.type_id != 2"
													>
														{{ x.name }}
													</div>
												</div>

												<div class="text-12 font-400 text-[#8A8A8A] whitespace-nowrap">
													{{ getTimeDiffer(x.created_at) }}
												</div>
											</router-link>
											<div class="h-3"></div>
											<router-link
												to="/notify?is_official=0"
												class="block text-center text-main-400 pointer"
												@click="isShowNotification = false"
												>觀看全部通知</router-link
											>
										</div>
										<!-- no notification -->
										<div v-if="useNotification.notifications.length == 0">
											<div class="mb-3">
												<img src="/img/35.png" class="h-20 block mx-auto" />
											</div>
											<div class="text-center text-[#8A8A8A]">
												目前沒有通知喔 <br />
												趕快去探索你感興趣的文章並追蹤喜歡的作者吧！
											</div>
											<div class="mt-4 text-center">
												<router-link
													to="/explore"
													class="rounded-8 bg-[#0044FF] text-white py-2 px-8"
													@click="isShowNotification = false"
													>探索去</router-link
												>
											</div>
											<div class="h-5"></div>
										</div>
									</div>

									<!-- offical notification -->
									<div v-if="notificationTypeID == 2">
										<div v-if="useNotification.notificationsOfficial.length > 0">
											<router-link
												:to="x.url"
												class="flex items-center space-x-2 px-2 py-3"
												:class="{ 'bg-[#F7F7F7]': x.is_read == false }"
												v-for="(x, i) of useNotification.notificationsOfficial"
												:key="i"
												@click="clickNotification(x)"
											>
												<img src="/img/20.png" alt="Notification" class="h-10 w-10" />
												<div>
													<div class="text-sm">
														{{ x.name }}
													</div>
												</div>
												<div class="text-xs text-gray-500 whitespace-nowrap">
													{{ getTimeDiffer(x.created_at) }}
												</div>
											</router-link>
											<div class="h-3"></div>

											<router-link
												to="/notify?is_official=1"
												class="block text-center text-main-400 pointer"
												@click="isShowNotification = false"
												>觀看全部通知</router-link
											>
										</div>

										<!-- no notification -->
										<div v-if="useNotification.notificationsOfficial.length == 0">
											<div class="mb-3">
												<img src="/img/35.png" class="h-20 block mx-auto" />
											</div>
											<div class="text-center text-[#8A8A8A]">目前沒有官方通知喔 <br /></div>
											<div class="mt-4 text-center">
												<router-link
													to="/explore"
													class="rounded-8 bg-[#0044FF] text-white py-2 px-8"
													@click="isShowNotification = false"
													>探索去</router-link
												>
											</div>
											<div class="h-5"></div>
										</div>
									</div>
								</div>
							</div>

							<!-- User Profile Icon and Popup -->
							<div class="lg:block relative ml-3 pt-1 lg:pt-0">
								<template v-if="!store.isLogin">
									<router-link :to="loginUrl" class="rounded-8 bg-[#0044FF] text-white py-[6px] px-5"
										>開始使用</router-link
									>
								</template>

								<template v-else>
									<button class="relative text-gray-600" @click.stop="toggleUserProfile">
										<img
											:src="asset(store.user.photo, '_user.png')"
											:alt="store.user.name"
											class="h-8 w-8 rounded-full"
										/>
									</button>
									<!-- h-[calc(100vh-50px)]  -->
									<div
										v-if="isShowUserProfile"
										class="absolute top-[-6px] right-[-1rem] lg:top-12 h-screen lg:h-auto lg:right-0 bg-white shadow lg:rounded-8 rounded w-[200px] z-[1000]"
										ref="userProfilePopup"
										v-on-click-outside="closeProfile"
									>
										<div class="h-0 lg:h-4"></div>
										<div class="flex flex-col h-full text-14 lg:text-16">
											<router-link
												:to="`/user/${this.store.user.id}`"
												class="flex items-center space-x-2 text-[#222] font-500 px-4 py-3 hover:bg-[#F7F7F7]"
												@click="toggleUserProfile()"
											>
												<img
													:src="asset(store.user.photo, '_user.png')"
													:alt="store.user.name"
													class="w-10 h-10 rounded-full"
												/>
												<span>個人頁面</span>
											</router-link>
											<router-link
												to="/my/info"
												class="flex items-center space-x-2 text-[#8A8A8A] font-500 hover:bg-[#F7F7F7] px-4 py-3"
												@click="toggleUserProfile()"
											>
												<img src="/img/10.png" alt="Info" class="w-4 h-4" />
												<span>個人資料</span>
											</router-link>
											<router-link
												to="/my/contents"
												class="flex items-center space-x-2 text-[#8A8A8A] font-500 hover:bg-[#F7F7F7] px-4 py-3"
												@click="toggleUserProfile()"
											>
												<img src="/img/11.png" alt="Draft" class="w-4 h-4" />
												<span>內容管理</span>
											</router-link>
											<router-link
												to="/my/collects"
												class="flex items-center space-x-2 text-[#8A8A8A] font-500 hover:bg-[#F7F7F7] px-4 py-3"
												@click="toggleUserProfile()"
											>
												<img src="/img/91.png" alt="Bookmark" class="w-4 h-4" />
												<span>收藏</span>
											</router-link>

											<router-link
												to="/my/notify-settings"
												class="flex items-center space-x-2 text-[#8A8A8A] font-500 hover:bg-[#F7F7F7] px-4 py-3"
												@click="toggleUserProfile()"
											>
												<img src="/img/14.png" alt="Bell" class="w-4 h-4" />
												<span>通知設定</span>
											</router-link>

											<router-link
												to="/my/tags"
												class="flex items-center space-x-2 text-[#8A8A8A] font-500 hover:bg-[#F7F7F7] px-4 py-3"
												@click="toggleUserProfile()"
											>
												<img src="/img/38.png" alt="Draft" class="w-4 h-4" />
												<span>個人主頁標籤</span>
											</router-link>

											<router-link
												to="/my/order"
												class="flex items-center space-x-2 text-[#8A8A8A] font-500 hover:bg-[#F7F7F7] px-4 py-3"
												@click="toggleUserProfile()"
											>
												<img src="/img/98.png" alt="Draft" class="w-4 h-4" />
												<span>訂單紀錄</span>
											</router-link>

											<router-link
												to="/my/plans"
												class="flex items-center space-x-2 text-[#8A8A8A] font-500 hover:bg-[#F7F7F7] px-4 py-3"
												@click="toggleUserProfile()"
											>
												<img src="/img/101.png" alt="Draft" class="w-4 h-4" />
												<span>我建立的方案</span>
											</router-link>

											<div
												class="mt-auto pointer flex items-center space-x-2 text-[#8A8A8A] font-500 hover:bg-[#F7F7F7] px-4 py-3"
												@click="
													logout();
													toggleUserProfile();
												"
											>
												<img src="/img/17.png" alt="Logout" class="w-4 h-4" />
												<span>登出</span>
											</div>
											<div class="lg:h-0 h-10"></div>
											<div class="lg:h-0 h-10"></div>
											<div class="lg:h-0 h-10"></div>
											<div class="lg:h-0 h-4"></div>
										</div>
										<div class="h-4"></div>
									</div>
								</template>
							</div>
						</div>
					</div>
				</div>
			</header>

			<!-- header spacing -->
			<div style="height: 60px"></div>

			<!--
			..######...#######..##....##.########.########.##....##.########
			.##....##.##.....##.###...##....##....##.......###...##....##...
			.##.......##.....##.####..##....##....##.......####..##....##...
			.##.......##.....##.##.##.##....##....######...##.##.##....##...
			.##.......##.....##.##..####....##....##.......##..####....##...
			.##....##.##.....##.##...###....##....##.......##...###....##...
			..######...#######..##....##....##....########.##....##....##...
			-->
			<div class="flex-auto">
				<slot></slot>
			</div>

			<!-- spacing for mobile -->
			<div class="h-10 lg:h-0"></div>
			<div class="h-5 lg:h-0"></div>

			<!--
			.##........#######.....###....########..####.##....##..######..
			.##.......##.....##...##.##...##.....##..##..###...##.##....##.
			.##.......##.....##..##...##..##.....##..##..####..##.##.......
			.##.......##.....##.##.....##.##.....##..##..##.##.##.##...####
			.##.......##.....##.#########.##.....##..##..##..####.##....##.
			.##.......##.....##.##.....##.##.....##..##..##...###.##....##.
			.########..#######..##.....##.########..####.##....##..######..
			-->
			<div
				class="fixed pin-x pin-y z-999 h-screen w-screen flex items-center justify-center loading-overlay"
				:class="{ active: store.isLoading }"
			>
				<div class="text-white text-24 rubberBand animated">Loading...</div>
			</div>
		</div>

		<!--
		..######..########....###....########...######..##.....##.....#######..##.....##.########.########..##..........###....##....##
		.##....##.##.........##.##...##.....##.##....##.##.....##....##.....##.##.....##.##.......##.....##.##.........##.##....##..##.
		.##.......##........##...##..##.....##.##.......##.....##....##.....##.##.....##.##.......##.....##.##........##...##....####..
		..######..######...##.....##.########..##.......#########....##.....##.##.....##.######...########..##.......##.....##....##...
		.......##.##.......#########.##...##...##.......##.....##....##.....##..##...##..##.......##...##...##.......#########....##...
		.##....##.##.......##.....##.##....##..##....##.##.....##....##.....##...##.##...##.......##....##..##.......##.....##....##...
		..######..########.##.....##.##.....##..######..##.....##.....#######.....###....########.##.....##.########.##.....##....##...
		-->
		<div
			class="fixed w-screen left-0 top-0 bg-white h-[100vh] search-overlay flex flex-col z-[900]"
			v-if="isShowSearchSuggestionMobile"
		>
			<!-- header spacing -->
			<div style="height: 62px"></div>

			<div class="h-5"></div>
			<div class="container">
				<div class="relative border w-full">
					<input
						type="text"
						placeholder="搜尋關鍵字"
						class="pl-10 pr-5 py-2 w-full rounded-8 bg-white"
						@keyup.enter="searchMobileDo()"
						@change="getSearchSuggestion()"
						v-model="search"
						ref="searchInMobile"
					/>
					<img
						src="/img/02.png"
						alt="Search"
						class="absolute left-2 top-1/2 transform -translate-y-1/2 w-5 h-5 text-gray-400"
					/>
					<img
						src="/img/88.png"
						alt="clear"
						class="absolute right-2 top-1/2 transform -translate-y-1/2 w-5 h-5 text-gray-400 pointer"
						@click="clearSearch()"
					/>
				</div>
			</div>

			<div class="h-3"></div>

			<!-- suggestion result -->
			<div class="container overflow-auto" style="height: calc(100svh - 240px)">
				<div v-if="searchSuggestion.articles.length > 0">
					<h2 class="text-14 text-[#8A8A8A] mb-4">推薦文章</h2>

					<div>
						<router-link
							:to="`/article/${x.id}`"
							class="text-[#222] block mb-2 truncate"
							v-for="(x, i) of searchSuggestion.articles"
							:key="i"
							@click="
								isShowSearchSuggestion = false;
								isShowSearchSuggestionMobile = false;
								resetSearchSuggestion();
							"
							>{{ x.name }}</router-link
						>
					</div>
					<div class="h-1 border-b border-[#F1F1F1] mb-3"></div>
				</div>

				<div v-if="searchSuggestion.users.length > 0">
					<h2 class="text-14 text-[#8A8A8A] mb-4">創作者</h2>

					<div class="grid grid-cols-2 gap-3">
						<div class="w-full">
							<router-link
								:to="`/user/${x.id}`"
								class="block w-full mb-2"
								v-for="(x, i) of searchSuggestion.users"
								@click="
									isShowSearchSuggestion = false;
									isShowSearchSuggestionMobile = false;
									resetSearchSuggestion();
								"
								:key="i"
							>
								<div class="flex items-center">
									<img class="w-10 h-10 rounded-full mr-2" :src="asset(x.photo, '_user.png')" />
									<div class="flex-1">
										<div class="text-14 font-500 max-w-[120px] truncate block">
											{{ x.name }}
										</div>
										<p class="text-[#8A8A8A] text-12 w-full truncate" v-if="x.username">
											@{{ x.username }}
										</p>
									</div>
								</div>
							</router-link>
						</div>
					</div>
					<div class="h-1 border-b border-[#F1F1F1] mt-5 mb-3"></div>
				</div>

				<div v-if="searchSuggestion.tags.length > 0">
					<h2 class="text-14 text-[#8A8A8A] mb-4">標籤</h2>
					<div>
						<router-link
							:to="`/tag/${x.id}`"
							v-for="(x, i) of searchSuggestion.tags"
							:key="i"
							class="bg-[#F1F1F1] py-1 px-4 rounded-full inline-block mr-2 mb-2 pointer"
							@click="
								isShowSearchSuggestion = false;
								isShowSearchSuggestionMobile = false;
								resetSearchSuggestion();
							"
						>
							#{{ x.name }}</router-link
						>
					</div>
				</div>
			</div>

			<div class="mt-auto w-full px-4">
				<button
					v-if="search.length > 0"
					class="text-center block text-14 border-[#222] border rounded-8 py-3 w-full"
					@click="searchMobileDo()"
				>
					查看更多
				</button>
			</div>
		</div>

		<!--
		.##.....##..#######..########..####.##.......########....########...#######..########.########..#######..##.....##
		.###...###.##.....##.##.....##..##..##.......##..........##.....##.##.....##....##.......##....##.....##.###...###
		.####.####.##.....##.##.....##..##..##.......##..........##.....##.##.....##....##.......##....##.....##.####.####
		.##.###.##.##.....##.########...##..##.......######......########..##.....##....##.......##....##.....##.##.###.##
		.##.....##.##.....##.##.....##..##..##.......##..........##.....##.##.....##....##.......##....##.....##.##.....##
		.##.....##.##.....##.##.....##..##..##.......##..........##.....##.##.....##....##.......##....##.....##.##.....##
		.##.....##..#######..########..####.########.########....########...#######.....##.......##.....#######..##.....##
		-->
		<div class="lg:hidden fixed bottom-0 left-0 right-0 bg-white shadow-md z-[999]">
			<div class="flex justify-around py-2">
				<router-link
					class="flex flex-col items-center"
					:class="{ 'opacity-50': bottomTabIndex != 0 || isShowSearchSuggestionMobile == true }"
					to="/"
					@click="resetSearchSuggestion()"
				>
					<img src="/img/30.png" class="w-8" />
				</router-link>

				<router-link
					class="flex flex-col items-center"
					:class="{ 'opacity-50': bottomTabIndex != 1 || isShowSearchSuggestionMobile == true }"
					to="/explore"
					@click="resetSearchSuggestion()"
				>
					<img src="/img/31.png" class="w-8" />
				</router-link>

				<div
					class="flex flex-col items-center"
					:class="{ 'opacity-50': isShowSearchSuggestionMobile == false }"
					@click="toggleSearchSuggestionMobile()"
				>
					<img src="/img/32.png" class="w-8" />
				</div>

				<router-link
					class="flex flex-col items-center"
					:class="{ 'opacity-50': bottomTabIndex != 3 || isShowSearchSuggestionMobile == true }"
					to="/notify"
					@click="resetSearchSuggestion()"
				>
					<img src="/img/33.png" class="w-8" />
				</router-link>

				<router-link
					class="flex flex-col items-center"
					:class="{ 'opacity-50': bottomTabIndex != 4 || isShowSearchSuggestionMobile == true }"
					@click="resetSearchSuggestion()"
					:to="`/user/${this.store.user.id}`"
					v-if="store.isLogin"
				>
					<img src="/img/34.png" class="w-8" />
				</router-link>

				<router-link
					class="flex flex-col items-center"
					:class="{ 'opacity-50': bottomTabIndex != 4 || isShowSearchSuggestionMobile == true }"
					to="/login"
					v-else
					@click="resetSearchSuggestion()"
				>
					<img src="/img/34.png" class="w-8" />
				</router-link>
			</div>
		</div>
	</main>
</template>

<script>
import { useStore } from '../../store/main';

// 測試 Sentry 是否正常運作
// import * as Sentry from "@sentry/vue"; // 新增這行
// try {
//   throw new Error('測試 Sentry 是否正常運作');
// } catch (error) {
//   Sentry.captureException(error);
//   console.error('Sentry 測試錯誤已發送');
// }

export default {
	setup() {
		const store = useStore();
		return {
			store,
		};
	},

	data() {
		return {
			loginUrl: '/login',
			search: '',
			isShowNotification: false,
			isShowUser: false,
			isShowUserProfile: false,
			notificationsOfficial: [],
			notifications: [],
			notificationTypeID: 1,
			isShowSearchSuggestion: false,
			isShowSearchSuggestionMobile: false,
			searchSuggestion: {
				articles: [],
				users: [],
				tags: [],
			},
			isGotSearchSuggestions: false,
			lineLoginUrl: '',
			bottomTabIndex: null,
		};
	},

	watch: {
		$route(to) {
			this.checkUrl(to.path);

			this.loginUrl = '/login?redirect=' + encodeURIComponent(to.fullPath);
		},
		search() {
			this.getSearchSuggestion();
		},
	},

	async mounted() {
		window.layoutDefault = this;
	},

	methods: {
		checkUrl(q) {
			this.bottomTabIndex = null;
			if (q == '/explore') {
				this.bottomTabIndex = 1;
			}
			if (q == '/') {
				this.bottomTabIndex = 0;
			}
			if (q == '/notify') {
				this.bottomTabIndex = 3;
			}
			if (this.store.isLogin) {
				if (q == `/user/${this.store.user.id}`) {
					this.bottomTabIndex = 4;
				}
			} else {
				if (q == '/login') {
					this.bottomTabIndex = 4;
				}
			}
		},

		disableBodyScroll() {
			document.querySelector('.search-overlay').style.display = 'block';
			document.body.style.overflow = 'hidden';
		},

		enableBodyScroll() {
			document.body.style.overflow = '';
		},

		toggleSearchSuggestionMobile() {
			this.isShowSearchSuggestionMobile = !this.isShowSearchSuggestionMobile;
			if (this.isShowSearchSuggestionMobile) {
				// Open the overlay
				this.$nextTick(() => {
					this.$refs.searchInMobile.focus();
					this.disableBodyScroll();
				});
			} else {
				this.$nextTick(() => {
					// Close the overlay
					// document.querySelector('.search-overlay').style.display = 'none';
					this.enableBodyScroll();
				});
			}
		},

		closeSearchSuggestion() {
			this.isShowSearchSuggestion = false;
		},

		closeNotification() {
			this.isShowNotification = false;
		},

		closeProfile() {
			this.isShowUserProfile = false;
		},

		clickNotification(q) {
			this.isShowNotification = false;
			q.is_read = true;
			this.useNotification.setRead(q.id);
		},

		showSearchSuggestion() {
			this.isShowSearchSuggestion = true;
			if (!this.isGotSearchSuggestions) {
				this.getSearchSuggestion();
			}
			this.isGotSearchSuggestions = true;
		},

		async getSearchSuggestion() {
			try {
				if (this.search.length > 0) {
					const condition = { name: this.search };
					const resultHelper = await this.useHelper.getSearchSuggestion(condition);
					this.searchSuggestion.articles = resultHelper.extend.articles;
					this.searchSuggestion.users = resultHelper.extend.users;
					this.searchSuggestion.tags = resultHelper.extend.tags;
					this.isShowSearchSuggestion = true;
				} else {
					this.isShowSearchSuggestion = false;
					this.searchSuggestion.articles = [];
					this.searchSuggestion.users = [];
					this.searchSuggestion.tags = [];
				}
			} catch (e) {}
		},

		logout() {
			this.store.logout();
			this.$router.push('/');
		},

		toggleNotification() {
			this.isShowNotification = !this.isShowNotification;
			if (this.isShowNotification) {
				this.isShowUserProfile = false;
			}
		},

		toggleUserProfile() {
			this.isShowUserProfile = !this.isShowUserProfile;
			if (this.isShowUserProfile) {
				this.isShowNotification = false;
			} else {
				this.resetSearchSuggestion();
			}
		},

		setAllRead() {
			this.useNotification.setAllRead();
			this.toast('全部標示已讀');
		},

		changeNotificationType(q) {
			this.notificationTypeID = q;
		},

		onClickSearch() {
			if (this.search.length > 0) {
				this.isShowSearchSuggestion = true;
			}
		},

		clearSearch() {
			this.search = '';
			this.searchSuggestion.articles = [];
			this.searchSuggestion.users = [];
			this.searchSuggestion.tags = [];
		},

		resetSearchSuggestion() {
			this.search = '';
			this.searchSuggestion.articles = [];
			this.searchSuggestion.users = [];
			this.searchSuggestion.tags = [];

			this.isShowSearchSuggestion = false;
			this.isShowSearchSuggestionMobile = false;

			this.enableBodyScroll();
		},

		searchDo() {
			if (this.search.length == 0) {
				return;
			}

			this.isShowSearchSuggestionMobile = false;
			const encodedSearch = encodeURIComponent(this.search);
			this.$router.push(`/search/contents?keyword=${encodedSearch}`);

			this.resetSearchSuggestion();
		},

		searchMobileDo() {
			if (this.search.length == 0) {
				return;
			}

			this.isShowSearchSuggestionMobile = false;
			const encodedSearch = encodeURIComponent(this.search);
			this.$router.push(`/search/contents?keyword=${encodedSearch}`);

			this.resetSearchSuggestion();
		},

		hideSearch() {
			const self = this;
			setTimeout(function () {
				self.search = '';
				self.isShowSearchSuggestion = false;
			}, 200);
		},
	},
};
</script>
