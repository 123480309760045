/**
 * pinia for 訂單的api
 */

import { defineStore } from 'pinia';

import { post } from '../helpers/main.js';

export default defineStore('order', {
	state: () => ({}),
	actions: {
		async update(q) {
			try {
				const response = await post('/order/update', q);
				return response.data;
			} catch (error) {
				console.error('Failed to update', error);
			}
		},

		async getItems(condition = {}) {
			let baseCondition = {
				_pageSize: 10,
				_page: 1,
				_orderField: 'id',
				_orderDirection: 'desc',
			};

			condition = { ...baseCondition, ...condition };

			try {
				const response = await post('/order/get_list', condition);
				return response.data;
			} catch (error) {
				console.error('Failed to fetch items', error);
			}
		},

		async getItem(q) {
			try {
				const response = await post('/order/get_item', { id: q });
				return response.data;
			} catch (error) {
				console.error('Failed to fetch items', error);
			}
		},
		async getProduct(orderID) {
			try {
				const response = await post('/order/get_product', { order_id: orderID });
				return response.data;
			} catch (error) {
				console.error('Failed to fetch product', error);
			}
		},
		async renewOrder(orderID, status) {
			try {
				const response = await post('/order/renew', { order_id: orderID, status: status });
				return response.data;
			} catch (error) {
				console.error('Failed to renew order', error);
			}
		},
	},
});
