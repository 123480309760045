/**
 * pinia for 首頁的api
 */

import { defineStore } from 'pinia';

import { post } from '../helpers/main.js';

export default defineStore('home', {
	state: () => ({}),
	actions: {
		async getItems(condition = {}) {
			console.log('home store getItems');

			let baseCondition = {
				_pageSize: 10,
				_page: 1,
				_orderField: 'id',
				_orderDirection: 'asc',
				name: '',
			};

			condition = { ...baseCondition, ...condition };
			try {
				// 先應急用這支
				// const response = await post('/home/get_list', condition);

				// 等熱門文章api ready要換這支
				const response = await post('/article/get_list_by_user', condition);

				if (response.code !== 200) {
					return response.data;
				} else {
				}
			} catch (error) {
				console.error('Failed to fetch items', error);
			}
		},
	},
});
