/**
 * pinia 的主檔
 */

import { defineStore } from 'pinia';

import useNotificationStore from './notification';

export const useStore = defineStore('main', {
	state: () => ({
		user: {
			id: null,
			name: '',
			email: '',
			permissions: [],
		},
		isLogin: false,
		isLoading: false,
		isModal: {
			logout: false,
		},
	}),

	getters: {},

	actions: {
		setLoading(q) {
			this.isLoading = q;
		},

		setUser(q) {
			this.user.id = q.id;
			this.user.email = q.email;
			this.user.name = q.name;
			this.user.photo = q.photo;

			this.isLogin = true;
			localStorage.setItem('isLogin', this.isLogin);

			window.isLogin = true;
			window.user = this.user;

			// get notification
			const temp = useNotificationStore();

			// none official notification
			temp.getItems({ is_official: false, _pageSize: 5 }, true);

		},

		logout() {
			this.user.id = null;
			this.user.email = '';
			this.user.name = '';
			this.user.permissions = [];
			this.isLogin = false;

			window.isLogin = false;
			window.user = {
				id: null,
				name: '',
				email: '',
				permissions: [],
			};

			localStorage.removeItem('token');
			localStorage.removeItem('isLogin');
		},
	},
});
