/**
 * vue的主檔
 */

// custom css
import './style/_index.css';
import 'vue-toast-notification/dist/theme-bootstrap.css';
import '@vuepic/vue-datepicker/dist/main.css';

import * as Sentry from '@sentry/vue';
import VueDatePicker from '@vuepic/vue-datepicker';
import { vOnClickOutside } from '@vueuse/components';
// 使用 @vueuse/head 作為頭部管理解決方案
import { createHead, useHead } from '@vueuse/head';
import { createApp } from 'vue';
import VueGtag from 'vue-gtag';
import ToastPlugin from 'vue-toast-notification';
import VueLazyLoad from 'vue3-lazyload';

import App from './App.vue';
import constants from './constants/_index.js';
import i18n from './i18n.js';
import router from './router/index.js';
import { pinia } from './store/index.js';
// global use store, for speed up develop, remove this after finished this project
import {
	useArticleCollectStore,
	useArticleCommentLikeStore,
	useArticleCommentStore,
	useArticleLikeStore,
	useArticleStore,
	useArticleTopicStore,
	useArticleTypeStore,
	useAuthStore,
	useCartCalculateStore,
	useCouponStore,
	useExploreStore,
	useHelperStore,
	useHomeStore,
	useNotificationStore,
	useOrderStore,
	usePhotoStore,
	useProductStore,
	useSelfAnalyticsStore,
	useSelfArticleCollectStore,
	useSelfArticleStore,
	useSelfBlockStore,
	useSelfCouponCodeStore,
	// self ------------------------
	useSelfMeStore,
	useSelfOrderStore,
	useSelfUserTagStore,
	useTagStore,
	useUserRelationStore,
	useUserStore,
	useUserTagStore,
} from './store/index.js';
import { useStore } from './store/main.js';

// 初始化環境設定
if (process.env.NODE_ENV === 'production') {
	console.log = () => {};
	console.error = () => {};
	console.warn = () => {};
}

// 建立 Vue 應用實例
const app = createApp(App);

// 創建並配置 head
const head = createHead();
app.use(head);

Sentry.init({
	app,
	dsn: process.env.VUE_APP_SENTRY_DSN,
	integrations: [Sentry.browserTracingIntegration({ router })],

	// Performance Monitoring
	tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
	// Session Replay
	replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
	replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

/*
..######...##........#######..########.....###....##..........##.....##....###....########..####....###....########..##.......########
.##....##..##.......##.....##.##.....##...##.##...##..........##.....##...##.##...##.....##..##....##.##...##.....##.##.......##......
.##........##.......##.....##.##.....##..##...##..##..........##.....##..##...##..##.....##..##...##...##..##.....##.##.......##......
.##...####.##.......##.....##.########..##.....##.##..........##.....##.##.....##.########...##..##.....##.########..##.......######..
.##....##..##.......##.....##.##.....##.#########.##...........##...##..#########.##...##....##..#########.##.....##.##.......##......
.##....##..##.......##.....##.##.....##.##.....##.##............##.##...##.....##.##....##...##..##.....##.##.....##.##.......##......
..######...########..#######..########..##.....##.########.......###....##.....##.##.....##.####.##.....##.########..########.########
*/
app.config.globalProperties.isProduction = process.env.NODE_ENV === 'production';
app.config.globalProperties.isDev = process.env.NODE_ENV !== 'production';
app.config.globalProperties.$version = process.env.VUE_APP_VERSION || Date.now();
app.config.globalProperties.option = constants;

/*
..######...##........#######..########.....###....##..........########.##.....##.##....##..######..########.####..#######..##....##
.##....##..##.......##.....##.##.....##...##.##...##..........##.......##.....##.###...##.##....##....##.....##..##.....##.###...##
.##........##.......##.....##.##.....##..##...##..##..........##.......##.....##.####..##.##..........##.....##..##.....##.####..##
.##...####.##.......##.....##.########..##.....##.##..........######...##.....##.##.##.##.##..........##.....##..##.....##.##.##.##
.##....##..##.......##.....##.##.....##.#########.##..........##.......##.....##.##..####.##..........##.....##..##.....##.##..####
.##....##..##.......##.....##.##.....##.##.....##.##..........##.......##.....##.##...###.##....##....##.....##..##.....##.##...###
..######...########..#######..########..##.....##.########....##........#######..##....##..######.....##....####..#######..##....##
*/
// global use store, for speed up develop, remove this after finished this project

app.config.globalProperties.alert = function (q) {
	window.alert(q);
};

app.config.globalProperties.toast = function (message, type = 'success', position = 'top-right') {
	this.$toast.open({
		message: message,
		type: type,
		position: position,
		// duration: 10000000,
	});
};

app.config.globalProperties.toDate = function (q) {
	let text = '--';
	try {
		const dateObj = new Date(q);
		if (isNaN(dateObj.getTime())) {
			throw new Error('Invalid date'); // Handle invalid date input
		}

		// Get the browser's timezone offset in minutes
		const timezoneOffsetMinutes = dateObj.getTimezoneOffset();

		// Convert the offset to milliseconds and adjust the time
		dateObj.setTime(dateObj.getTime() - timezoneOffsetMinutes * 60 * 1000);

		const year = dateObj.getFullYear();
		const month = ('0' + (dateObj.getMonth() + 1)).slice(-2); // Months are zero-indexed
		const day = ('0' + dateObj.getDate()).slice(-2);
		text = `${year}/${month}/${day}`;
	} catch (e) {
		console.error('Error in toDate:', e.message);
	}
	return text;
};

app.config.globalProperties.toDateTime = function (q) {
	let text = '--';
	try {
		const dateObj = new Date(q);
		if (isNaN(dateObj.getTime())) {
			throw new Error('Invalid date'); // Handle invalid date input
		}

		// Get the browser's timezone offset in minutes
		const timezoneOffsetMinutes = dateObj.getTimezoneOffset();

		// Convert the offset to milliseconds and adjust the time
		dateObj.setTime(dateObj.getTime() - timezoneOffsetMinutes * 60 * 1000);

		const year = dateObj.getFullYear();
		const month = ('0' + (dateObj.getMonth() + 1)).slice(-2);
		const day = ('0' + dateObj.getDate()).slice(-2);
		const hours = ('0' + dateObj.getHours()).slice(-2);
		const minutes = ('0' + dateObj.getMinutes()).slice(-2);
		const seconds = ('0' + dateObj.getSeconds()).slice(-2);
		text = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
	} catch (e) {
		console.error('Error in toDateTime:', e.message);
	}
	return text;
};

app.config.globalProperties.typeText = function (items, id, defaultText = '--', key = 'id') {
	try {
		let temp = null;
		temp = items.find((z) => z[key] === id);
		if (temp) {
			defaultText = temp.name;
		}
	} catch (e) {}
	return defaultText;
};

app.config.globalProperties.asset = function (q, defaultImage = '_image.png') {
	if (q === null || q === '') {
		return '/img/' + defaultImage;
	} else {
		return process.env.VUE_APP_ASSET_URL + q;
	}
};

app.config.globalProperties.filterDatetime = function (q) {
	try {
		q = q.replace('T', ' ');
	} catch (e) {}
	return q;
};

app.config.globalProperties.filterDate = function (q) {
	try {
		q = q.substr(0, 10);
		// relace - to /
		q = q.replace(/-/g, '/');
	} catch (e) {}
	return q;
};

app.config.globalProperties.changePhoto = function (variable, key, accept = 'image/png, image/jpeg') {
	const uploadFileDo = (variable, key, file) => {
		const self = this;
		let formData = new FormData();
		formData.append('files', file, file.name);
		let xhr = new XMLHttpRequest();
		const token = localStorage.getItem('token');
		xhr.open('POST', process.env.VUE_APP_API_URL + '/v1/_helper/upload_files_google', true);
		xhr.setRequestHeader('authorization', `Bearer ${token}`);

		xhr.onload = function () {
			if (xhr.status === 200) {
				let json = JSON.parse(xhr.response);
				variable[key] = json.files[0].base_name;
			} else {
				self.alert('Upload failed.');
			}
		};
		xhr.onerror = function () {
			self.alert('Upload failed.');
		};
		xhr.send(formData);
	};

	let fileInput = this.$el.querySelector('.file');
	fileInput = document.createElement('input');
	fileInput.setAttribute('type', 'file');
	fileInput.setAttribute('accept', accept);
	fileInput.classList.add('hidden');
	fileInput.addEventListener('change', () => {
		if (fileInput.files != null && fileInput.files[0] != null) {
			const file = fileInput.files[0];
			uploadFileDo(variable, key, file);
		} else {
		}
	});
	this.$el.appendChild(fileInput);
	fileInput.click();
};

app.config.globalProperties.getTimeDiffer = function (q) {
	let returnText = '--';
	try {
		const date = new Date(q + 'Z'); // Add 'Z' if the date from server is UTC without timezone

		const now = new Date();
		const diffMs = now - date;
		const diffMins = Math.round(diffMs / 60000);

		if (diffMins < 60) {
			returnText = `${diffMins}分鐘前`;
		} else if (diffMins < 1440) {
			const diffHours = Math.floor(diffMins / 60);
			returnText = `${diffHours}小時前`;
		} else {
			const diffDays = Math.floor(diffMins / 1440);
			returnText = `${diffDays}天前`;
		}
	} catch (e) {}
	return returnText;
};

app.config.globalProperties.setMeta = function (
	title = null,
	description = null,
	image = null,
	isFullTitle = false,
	og_type = null
) {
	if (title == null) {
		title = '微格 Wlog | 專為投資人設計的資訊交流空間';
	} else {
		if (isFullTitle == false) {
			title = title + ' | 微格Wlog';
		}
	}
	if (description == null) {
		description =
			'【微股力】全新平台【微格】專為投資人設計的資訊交流空間，匯聚#產業個股、#ETF、#新聞事件、#全球總經等分類內容，讓讀者快速找到所需的財經資訊。主題策展專區提供熱門話題如#買房穩賺or存股高利、#持股清單分享、#養老存款與投資必看、#股票投資心法，為創作者打造多元討論空間。';
	}

	if (image == null) {
		image = window.location.origin + '/img/og/default_meta.png';
	} else {
		image = this.asset(image);
	}
	if (og_type == null) {
		og_type = 'website';
	}

	useHead({
		title,
		meta: [
			{ name: 'description', content: description },
			{ property: 'og:description', content: description },
			{ property: 'og:image', content: image },
			{ property: 'og:image:secure_url', content: image },
			{ property: 'og:image:width', content: '800' },
			{ property: 'og:image:height', content: '600' },
			{ property: 'og:type', content: og_type },
			{ property: 'og:url', content: window.location.href },
			{ property: 'og:locale', content: 'zh_TW' },
			{ property: 'fb:app_id', content: '1095379741823178' },
		],
	});
};

app.use(pinia);
// app.use(CkeditorPlugin)

// 根據當前環境設置 GTAG ID
const gtagId =
	process.env.NODE_ENV === 'production' ? process.env.VUE_APP_GTAG_ID_PROD : process.env.VUE_APP_GTAG_ID_DEV;
app.use(VueGtag, {
	config: { id: gtagId },
});
app.use(VueLazyLoad, {
	preLoad: 1.3,
	attempt: 1,
});

app.directive('on-click-outside', vOnClickOutside);

app.config.globalProperties.useArticle = useArticleStore();
app.config.globalProperties.useUserRelation = useUserRelationStore();
app.config.globalProperties.useTag = useTagStore();
app.config.globalProperties.useUser = useUserStore();
app.config.globalProperties.useArticleCollect = useArticleCollectStore();
app.config.globalProperties.useArticleComment = useArticleCommentStore();
app.config.globalProperties.useArticleLike = useArticleLikeStore();
app.config.globalProperties.useNotification = useNotificationStore();
app.config.globalProperties.usePhoto = usePhotoStore();
app.config.globalProperties.useArticleType = useArticleTypeStore();
app.config.globalProperties.useHome = useHomeStore();
app.config.globalProperties.useExplore = useExploreStore();
app.config.globalProperties.useHelper = useHelperStore();
app.config.globalProperties.useCartCalculateHelper = useCartCalculateStore();
app.config.globalProperties.useArticleCommentLike = useArticleCommentLikeStore();
app.config.globalProperties.useArticleTopic = useArticleTopicStore();
app.config.globalProperties.useUserTag = useUserTagStore();
app.config.globalProperties.useAuth = useAuthStore();
app.config.globalProperties.useCoupon = useCouponStore();
app.config.globalProperties.useProduct = useProductStore();
app.config.globalProperties.useOrder = useOrderStore();

// self
app.config.globalProperties.useSelfMe = useSelfMeStore();
app.config.globalProperties.useSelfUserTag = useSelfUserTagStore();
app.config.globalProperties.useSelfArticleCollect = useSelfArticleCollectStore();
app.config.globalProperties.useSelfBlock = useSelfBlockStore();
app.config.globalProperties.useSelfArticle = useSelfArticleStore();
app.config.globalProperties.useSelfAnalytics = useSelfAnalyticsStore();
app.config.globalProperties.useSelfOrder = useSelfOrderStore();
app.config.globalProperties.useSelfCouponCode = useSelfCouponCodeStore();

app.config.globalProperties.store = useStore();

app.use(router);
app.use(i18n);
// head 已經在上方使用正確方式註冊

app.use(ToastPlugin);
app.component('VueDatePicker', VueDatePicker);

app.mount('#app');
