/**
 * global function
 */

import axios from 'axios';

async function post(url, data, prefix = '/v1') {
	const config = {};
	const token = localStorage.getItem('token');
	if (token) {
		config.headers = {
			authorization: `Bearer ${token}`,
		};
	}
	console.log('post', process.env.VUE_APP_API_URL + prefix + url, data, config);
	return await axios.post(process.env.VUE_APP_API_URL + prefix + url, data, config);
}

function isInclude(items, value) {
	return items.includes(value);
}

function getDate(date = new Date()) {
	const year = date.getFullYear();
	let month = date.getMonth() + 1;
	let day = date.getDate();
	if (month >= 1 && month <= 9) {
		month = '0' + month;
	}
	if (day >= 0 && day <= 9) {
		day = '0' + day;
	}
	const currentDate = year + '-' + month + '-' + day;
	return currentDate;
}

function isPermission(q, module = null) {
	if (module == null) {
		module = this.moduleName;
	}
	const permissionID = module + '-' + q;
	if (window.user) {
		const temp = window.user.permissions.find((z) => z == permissionID);
		return temp;
	}
	return null;
}

export { getDate, isInclude, isPermission, post };
