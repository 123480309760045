/**
 * pinia 主檔
 */

import { createPinia } from 'pinia';

import useArticleStore from './article';
import useArticleCollectStore from './articleCollect';
import useArticleCommentStore from './articleComment';
import useArticleCommentLikeStore from './articleCommentLike';
import useArticleLikeStore from './articleLike';
import useArticleTopicStore from './articleTopic';
import useArticleTypeStore from './articleType';
import useAuthStore from './auth';
import useCartCalculateStore from './cartCalculate';
import useCouponStore from './coupon';
import useExploreStore from './explore';
import useHelperStore from './helper';
import useHomeStore from './home';
import useNotificationStore from './notification';
import useOrderStore from './order';
import usePhotoStore from './photo';
import useProductStore from './product';
import useSelfAnalyticsStore from './selfAnalytics';
import useSelfArticleStore from './selfArticle';
import useSelfArticleCollectStore from './selfArticleCollect';
import useSelfBlockStore from './selfBlock';
import useSelfCouponCodeStore from './selfCouponCode';
// self
import useSelfMeStore from './selfMe';
import useSelfOrderStore from './selfOrder';
import useSelfUserTagStore from './selfUserTag';
import useTagStore from './tag';
import useUserStore from './user';
import useUserRelationStore from './userRelation';
import useUserTagStore from './userTag';

const pinia = createPinia();

export {
	pinia,
	useArticleCollectStore,
	useArticleCommentLikeStore,
	useArticleCommentStore,
	useArticleLikeStore,
	useArticleStore,
	useArticleTopicStore,
	useArticleTypeStore,
	useAuthStore,
	useCartCalculateStore,
	useCouponStore,
	useExploreStore,
	useHelperStore,
	useHomeStore,
	useNotificationStore,
	useOrderStore,
	usePhotoStore,
	useProductStore,
	useSelfAnalyticsStore,
	useSelfArticleCollectStore,
	useSelfArticleStore,
	useSelfBlockStore,
	useSelfCouponCodeStore,
	// self
	useSelfMeStore,
	useSelfOrderStore,
	useSelfUserTagStore,
	useTagStore,
	useUserRelationStore,
	useUserStore,
	useUserTagStore,
};
