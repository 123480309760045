/**
 * pinia for 使用者自己的封鎖的api
 */

import { defineStore } from 'pinia';

import { post } from '../helpers/main.js';

export default defineStore('selfUserBlock', {
	state: () => ({}),
	actions: {
		async getItems(condition = {}) {
			let baseCondition = {
				_pageSize: 10,
				_page: 1,
				_orderField: 'id',
				_orderDirection: 'asc',
				name: '',
			};

			condition = { ...baseCondition, ...condition };
			try {
				const response = await post('/self/block/get_list', condition);
				return response.data;
			} catch (error) {
				console.error('Failed to fetch items', error);
			}
		},

		async update(q) {
			try {
				const response = await post('/self/block/update', q);
				return response.data;
			} catch (error) {
				console.error('Failed to update', error);
			}
		},
	},
});
