/**
 * pinia for 標籤的api
 */

import { defineStore } from 'pinia';

import { post } from '../helpers/main.js';

export default defineStore('tag', {
	state: () => ({}),
	actions: {
		async getItems(condition = {}) {
			let baseCondition = {
				_pageSize: 10,
				_page: 1,
				_orderField: 'priority',
				_orderDirection: 'desc',
				name: '',
				is_official: true,
			};

			condition = { ...baseCondition, ...condition };
			try {
				const response = await post('/tag/get_list', condition);
				return response.data;
			} catch (error) {
				console.error('Failed to fetch items', error);
			}
		},
	},
});
